<script setup lang="ts">
import { VChip } from "vuetify/components/VChip";
import type { RouteLocationRaw } from "vue-router";

const emit = defineEmits<{
  (e: "click:close", v: unknown): void;
}>();

interface Props {
  to?: unknown;
  color?: string;
  size?: "x-small" | "small" | "default" | "large" | "x-large";
  closable?: boolean; // previously `close`
  variant?: "flat" | "text" | "outlined" | "plain" | "elevated" | "tonal";
  label?: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  to: undefined,
  color: undefined,
  size: "default",
  closable: undefined,
  variant: "flat",
  disabled: undefined,
});
</script>

<template>
  <VChip
    v-bind="{ ...props, to: props.to as RouteLocationRaw }"
    @click:close="(v) => emit('click:close', v)"
  >
    <slot />
  </VChip>
</template>
